<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      class="mb-7"
    >
      <template #header>
        <img
          src="@/assets/plugins/ubc_alcon_plugin.png"
          alt="ubc alcon logo"
          height="32"
        >
        UBC - Alcon Export
      </template>
      <template #subheader>
        Request an export for UBC - Alcon and view your active and previous exports
      </template>
    </PageHeader>

    <v-row class="flex-nowrap">
      <v-col cols="12">
        <div class="pb-0">
          This export will return a full list of <u>all</u> contacts in the file format built for the UBC plugin customized for Alcon.<br>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-start flex-wrap pt-2">
        <v-btn
          class="custom-button custom-button--blue px-11 mr-sm-5 mb-4 mb-sm-0"
          style="min-width: 150px"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="exportDialog = true"
        >
          Create Export
        </v-btn>

        <v-dialog
          v-model="exportDialog"
          scrollable
          max-width="560px"
        >
          <ExportDialog
            v-if="exportDialog"
            header="Export Contacts"
            :fields-to-export="exportFields"
            @export="onExport"
            @dismiss="exportDialog = false;"
          >
            <template #top>
              <div class="mb-6" style="color: #000;">
                Exporting contacts for UBC
              </div>
            </template>
          </ExportDialog>
        </v-dialog>
      </v-col>
    </v-row>

    <div v-if="currentExports.length" class="field__header mt-10">
      Active Exports
    </div>
    <ExportsTable
      v-if="currentExports.length"
      :exports="currentExports"
      :for-previous-exports="true"
    />

    <div class="field__header mt-10">
      Previous Exports
    </div>
    <ExportsTable
      :exports="previousExports"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ExportsTable from "@/views/Exports/components/ExportsTable";
import ExportDialog from "@/sharedComponents/ExportDialog";

export default {
  name: "UbcAlconExportsView",
  metaInfo: {
    title: 'UBC - Alcon Exports'
  },
  components: {
    PageHeader,
    ExportsTable,
    ExportDialog,
  },
  data() {
    return {
      currentExports: [],
      previousExports: [],
      eventSource: null,
      completedStatuses: ['completed', 'canceled', 'failed'],
      previousStatuses: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
      exportDialog: false,
      exportFields: [],
      exportType: 'ubc_alcon_contact',
    };
  },
  async created() {
    await this.getPreviousExports();
    await this.getCurrentExports();
    await this.getExportFields();

    this.eventSource = await this.$store.dispatch('mercure/export_type_subscribe', this.exportType);
    if (!this.eventSource) {
      return;
    }

    this.eventSource.onmessage = (msg) => {
      this.$store.dispatch('mercure/update_export_type_last_event', {
        exportType: this.exportType,
        lastEventId: msg.lastEventId
      });

      const parsedMessage = JSON.parse(msg.data);

      const currentExportIndex = this.currentExports.findIndex(elem => elem.id === parsedMessage.id) ?? null;
      if (currentExportIndex === -1) {
        this.currentExports.unshift(parsedMessage);
        return;
      }

      if (this.completedStatuses.includes(parsedMessage.status)) {
        this.$delete(this.currentExports, currentExportIndex);
        this.getPreviousExports();

        this.$store.commit('snackbar/showMessage', {
          color: "success",
          content: `An export has completed. Please check the section below for the results.`
        })
        return;
      }

      this.$set(this.currentExports, currentExportIndex, parsedMessage);
    };
  },
  beforeDestroy() {
    if (!this.eventSource) {
      return;
    }
    this.$store.dispatch('mercure/unsubscribe', this.eventSource)
  },
  methods: {
    async getPreviousExports() {
      const resp = await this.$rest.exports.get_collection({
        exportType: this.exportType,
        status: this.completedStatuses,
        sort: ['updatedAt:desc'],
      });
      this.previousExports = resp.data.items;
    },
    async getCurrentExports() {
      const resp = await this.$rest.exports.get_collection({
        exportType: this.exportType,
        status: this.previousStatuses,
        sort: ['createdAt:desc'],
      });
      this.currentExports = resp.data.items;
    },
    async getExportFields() {
      this.exportFields = (await this.$rest.exports.get_export_type(this.exportType)).data?.headers ?? [];
    },
    async onExport(details) {
      await this.$rest.exports.post_resource({
        "exportType": this.exportType,
        "exportData": {
          ...details
        },
      })

      await this.getCurrentExports();

      this.exportDialog = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}

.field {
  &__header {
    margin-bottom: 25px;
  }
}
</style>
